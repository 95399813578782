import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export interface MemberReminder {
  id: number;
  ehr_user_id: number;
  title: string;
  description: string;
  type: string;
  payload: unknown;
  member_user_id: number | null;
  member_name: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  remind_at: string | null;
  resolved_at: string | null;
}

type MemberReminders = MemberReminder[];

const getMemberReminders = (member_user_id: number, accessToken: string) => {
  return api
    .get<MemberReminders>(
      `/v1/ehr/notifications/member_reminders/members/${member_user_id}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useMemberReminders = (
  userId: number,
  options: UseQueryOptions<
    MemberReminders,
    Error,
    MemberReminders,
    string[]
  > = {}
): UseQueryResult<MemberReminders, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberReminders(userId),
    () => getMemberReminders(userId, accessToken),
    { enabled: !!accessToken && userId != null, ...options }
  );
};
