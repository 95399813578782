import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

interface ClinicalRecommendation {
  id: number;
  user_id: number;
  clinical_recommendation: string;
  clinical_recommendation_made_at: string;
}

const getClinicalRecommendations = (user_id: number, accessToken: string) => {
  return api
    .get<ClinicalRecommendation>(
      `/v2/clinician/members/${encodeURIComponent(
        user_id
      )}/clinical_recommendations`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useMemberClinicalRecommendations = (
  userId: number,
  options: UseQueryOptions<
    ClinicalRecommendation,
    Error,
    ClinicalRecommendation
  > = {}
): UseQueryResult<ClinicalRecommendation, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberClinicalRecommendations(userId),
    () => getClinicalRecommendations(userId, accessToken),
    {
      enabled: !!accessToken && userId != null,
      ...(options ?? {}),
    }
  );
};
