import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../../api";
import { transformAPIError } from "../../../utils";
import getQueryKeys from "../../query-keys";
import { Obsession } from "../../types";

type EditObsessionPayload = Pick<Obsession, "id" | "obsession">;

const editObsession = (
  values: EditObsessionPayload,
  accessToken: string | undefined
) =>
  api
    .patch<Obsession>(
      `/v1/therapy/obsessions/${values.id}`,
      {
        obsession: values.obsession,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useEditObsession = (): UseMutationResult<
  Obsession,
  Error,
  EditObsessionPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => editObsession(payload, accessToken), {
    onSuccess: (updatedObsession) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).obsessions(updatedObsession.user_id)
      ),
  });
};
