import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../../api";
import { transformAPIError } from "../../../utils";
import getQueryKeys from "../../query-keys";
import { Obsession } from "../../types";

type DeleteObsessionPayload = Pick<Obsession, "id" | "user_id">;

const deleteObsession = (
  values: DeleteObsessionPayload,
  accessToken: string | undefined
) =>
  api
    .delete<string>(
      `/v1/therapy/obsessions/${values.id}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useDeleteObsession = (): UseMutationResult<
  string,
  Error,
  DeleteObsessionPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => deleteObsession(payload, accessToken), {
    onSuccess: (_data, { user_id }) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).obsessions(user_id)
      ),
  });
};
