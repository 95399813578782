import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import {
  BillingCoveragesResponse,
  BillingCoveragesResponseTransformed,
} from "@features/intake/hooks/useBillingCoverages";
import { useQuery, UseQueryResult } from "react-query";

// we have a hook useBillingCoverages, this hook only works for intake team, scheduling team and in retool app
// we need to create a new hook that will work for clinician team with clinician authorization
export const useMemberBillingCoverages = (
  user_id: number
): UseQueryResult<BillingCoveragesResponseTransformed[], Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    ["member-chart-billing-coverage", user_id],
    async () => {
      return api
        .get<BillingCoveragesResponse>(
          `/v2/clinician/members/${user_id}/member_chart/billing_coverages`,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((res) => {
          const { data } = res;

          const arrayLength = data.appointment_count.length; // assuming that all arrays are the same length, just grab the length of an arbitrary one

          const result: BillingCoveragesResponseTransformed[] = [];

          for (let i = 0; i < arrayLength; i += 1) {
            const temp = {} as unknown;
            Object.keys(data).forEach((key) => {
              // we know that this object is not modified before this call, (therefore we know more than TS)
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              temp[key] = data[key][i];
            });
            result.push(temp as BillingCoveragesResponseTransformed);
          }

          return result;
        })
        .catch(transformAPIError);
    },
    {
      placeholderData: null,
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 60 * 60 * 1000, // 1 hour
    }
  );
};
