import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberMedication } from "@core/services/nocd-api/members";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberMedication = (
  userId: number
): UseQueryResult<MemberMedication[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberMedication(userId),
    (): Promise<MemberMedication[]> =>
      api
        .get<MemberMedication[]>(
          `/v1/therapy/members/${userId}/medication`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
