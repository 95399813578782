import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

interface IcdCodesHistory {
  primary_and_secondary_icd_codes: string[] | null;
  last_appt_general_icd_codes: string[] | null;
  last_completed_appt_date: string | null;
  last_completed_appt_id: number | null;
}

export const useMemberICDCodesHistory = (
  userId: number
): UseQueryResult<IcdCodesHistory, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberICDCodesHistory(userId),
    () =>
      api
        .get<IcdCodesHistory>(
          `/v2/clinician/members/${encodeURIComponent(
            userId
          )}/icd_codes_history`,
          {
            headers: { Authorization: accessToken },
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
