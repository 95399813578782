import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberMedication } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../query-keys";

interface EditMedicationPayload {
  medication_id: number;
  medication_name: string;
  dosage: number | null;
  units: string | null;
  frequency: string | null;
  is_current_medication: number;
  user_id: number;
  clinician_email: string;
}

const editMemberMedication = (
  payload: EditMedicationPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .patch<MemberMedication[]>(
      `/v1/therapy/members/${encodeURIComponent(
        payload.user_id
      )}/medication/${encodeURIComponent(payload.medication_id)}`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useEditMemberMedication = (): UseMutationResult<
  MemberMedication[],
  Error,
  EditMedicationPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const queryClient = useQueryClient();

  return useMutation((payload) => editMemberMedication(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberMedication(payload.user_id)
      ),
  });
};
