import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export interface BookingStatus {
  is_booking_enabled: boolean;
  internal_warning: string;
  member_warning: string;
  book_ptsd_sessions: boolean;
  internal_flag_name?: string;
}

interface Options
  extends UseQueryOptions<BookingStatus, Error, BookingStatus, string[]> {
  skipInactiveMemberCheck?: boolean;
  skipNoClinicianCheck?: boolean;
}

export const useBookingStatus = (
  userId: number,
  {
    skipInactiveMemberCheck = false,
    skipNoClinicianCheck = false,
    ...restOptions
  }: Options = {}
): UseQueryResult<BookingStatus, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberBookingStatus(
      userId,
      skipInactiveMemberCheck,
      skipNoClinicianCheck
    ),
    (): Promise<BookingStatus> =>
      api
        .get<{ status: BookingStatus }>(
          `/v2/clinician/members/${userId}/booking_status`,
          {
            params: {
              skip_inactive_member_check: Number(skipInactiveMemberCheck),
              skip_no_clinician_check: Number(skipNoClinicianCheck),
            },
            headers: { Authorization: accessToken },
          }
        )
        .then(({ data }) => data.status)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null, ...restOptions }
  );
};
