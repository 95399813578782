import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface CreateMemberAdminNotePayload {
  text: string;
  user_id: number;
  needs_scheduling_team_assistance: boolean;
  department: string;
  unavailable_reason?: string | null;
  unavailable_from?: Date | null;
  unavailable_to?: Date | null;
  markUnavailable?: boolean;
  date_range?: [Date | null, Date | null];
}

const createMemberAdminNote = (
  values: CreateMemberAdminNotePayload,
  accessToken: string | undefined
) =>
  api
    .post<unknown>(
      `/v1/therapy/members/${values.user_id}/admin_notes`,
      {
        text: values.needs_scheduling_team_assistance
          ? `Message to the scheduling team:\n${values.text}`
          : values.text,
        needs_scheduling_team_assistance:
          values.needs_scheduling_team_assistance,
        department: values.department,
        unavailable_reason: values.unavailable_reason,
        unavailable_from: values.date_range[0],
        unavailable_to: values.date_range[1],
        mark_unavailable: values.markUnavailable,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useCreateMemberAdminNote = (): UseMutationResult<
  unknown,
  Error,
  CreateMemberAdminNotePayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => createMemberAdminNote(payload, accessToken), {
    onSuccess: (_data, payload) =>
      Promise.all([
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberAdminNotes(payload.user_id)
        ),
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberAvailableStatus(payload.user_id)
        ),
      ]),
  });
};
